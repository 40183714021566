var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard project-route-index"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{staticClass:"white-row"},[_c('div',{staticClass:"show-project-portal-listing"},[_c('div',{staticClass:"form-area"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row top-gen-heading"},[_c('h1',{staticClass:"global-style-heading"},[_vm._v(" "+_vm._s(_vm.formData.name)+" ")])])]),_c('div',{staticClass:"col-sm-12"},[_c('form',{staticClass:"emp-create-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Employee Name: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],staticClass:"form-control",class:{
                              'input-border-errors':
                                _vm.$v.formData.name.$dirty &&
                                !_vm.$v.formData.name.required
                            },attrs:{"type":"text","placeholder":"Employee Name"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Employee Email: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],staticClass:"form-control",class:{
                              'input-border-errors':
                                _vm.$v.formData.email.$dirty &&
                                (!_vm.$v.formData.email.required ||
                                  !_vm.$v.formData.email.email)
                            },attrs:{"type":"email","placeholder":"Employee Email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password: "),_c('span',[_vm._v("(Leave this blank for default password.)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],staticClass:"form-control",attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.formData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "password", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"mobile"}},[_vm._v("Mobile:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.mobile),expression:"formData.mobile"}],staticClass:"form-control",attrs:{"type":"tel","placeholder":"Mobile"},domProps:{"value":(_vm.formData.mobile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "mobile", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-primary default-global-btn",attrs:{"disabled":_vm.disableSubmitBtn,"type":"submit","id":"save-form"}},[_vm._v(" Update ")]),(_vm.disableSubmitBtn)?_c('div',{staticClass:"form_submit_loader"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])]):_vm._e()])])])])])])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }