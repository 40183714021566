<template>
  <div class="viewDashBoard" v-cloak>
    <div class="container">
      <div
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <adminvue-dashboard-vue
          v-if="(getActiveUserData.userRoles == 'admin') || (getActiveUserData.userRoles == 'subadmin')"
        ></adminvue-dashboard-vue>
        <sales-team-lead-dashboard-vue
          v-else-if="(getActiveUserData.userRoles == 'salesteamlead') || (getActiveUserData.userRoles == 'salesmanager')"
        ></sales-team-lead-dashboard-vue>
        <sales-person-dashboard-vue
          v-else-if="getActiveUserData.userRoles == 'salesperson'"
        ></sales-person-dashboard-vue>
        <client-dashboard-vue
          v-else-if="getActiveUserData.userRoles == 'client'"
        ></client-dashboard-vue>
        <project-coordinators-dashboard-vue
          v-else-if="getActiveUserData.userRoles == 'projectcoordinators'"
        >
        </project-coordinators-dashboard-vue>
        <default-dashboard-vue v-else></default-dashboard-vue>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminvue from "./components/admin_dashboard";
import clientvue from "./components/client_dashboard";
import default_vue from "./components/default_dashboard";
import salespersonevue from "./components/salesperson_dashboard";
import salesteamleadtablevue from "./components/salesteamlead_dashboard";
import projectcoordinators from "./components/project _coordinators_dashboard.vue";

export default {
  components: {
    "client-dashboard-vue": clientvue,
    "adminvue-dashboard-vue": adminvue,
    "default-dashboard-vue": default_vue,
    "sales-person-dashboard-vue": salespersonevue,
    "sales-team-lead-dashboard-vue": salesteamleadtablevue,
    "project-coordinators-dashboard-vue": projectcoordinators,
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {};
  },
  mounted() {
    document.title =
      "PNC Work Bench - " + this.$router.app._route.meta.pageTitle;
  },
  methods: {}
};
</script>
