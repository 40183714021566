<template>
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
           <strong> Thread </strong>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12">
            <div class="row">
                <div class="col-md-4">
                  <strong> Sent By </strong>
                </div>
                <div class="col-md-8 text-center">
                  <strong> Message </strong>
                </div>
            </div>
            
        </div>
        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12">
            <strong> Date/Time </strong>                  
        </div>
      </div>
</template>

<script>
export default {
  name: "message-header",
  data() {
    return {};
  },
};
</script>