var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{staticClass:"dash-board-wrapper>"},[(_vm.showPop)?_c('popup'):_vm._e(),_c('div',{key:"4",staticClass:"white-row"},[_c('noti',{attrs:{"isHome":"true"}})],1),_c('div',{key:"2",staticClass:"white-row message-wraper-area margin-top-50"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h3',{staticClass:"global-style-heading"},[_vm._v(" Messages ")])]),_c('div',{staticClass:"col-md-6 pt-2 d-flex justify-content-end"},[_c('a',{staticClass:"refreshData mr-2",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.refreshMessages.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-redo-alt"})]),_c('router-link',{staticClass:"btn_viewDetails",attrs:{"to":{
                path: '/messages'
              },"tag":"a"}},[_vm._v(" View All ")])],1)]),_c('br'),_c('message-header'),_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.message_loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{key:"2"},[(
                typeof _vm.project_messages.data !== 'undefined' &&
                  Object.keys(_vm.project_messages.data).length
              )?_c('div',{staticClass:"message-wrapper"},_vm._l((_vm.project_messages.data),function(message,mes_index){return _c('div',{key:mes_index,class:[
                  mes_index == 0
                    ? 'thr-loop-row margin-top-20'
                    : 'thr-loop-row'
                ]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-12 col-sm-12"},[_c('div',{staticClass:"thrd-name"},[_c('router-link',{staticClass:"eclipse-para global-para-link2",attrs:{"to":{
                          path: '/projects/show/'+message.project_id+'/messages/show/'+message.id
                        },"tag":"p"}},[_vm._v(" "+_vm._s(message.porject_thread_subtitle)+" ")])],1)]),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-8 col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-4 col-lg-4 col-md-4 col-sm-12"},[_c('div',{staticClass:"per-name"},[_c('p',{staticClass:"eclipse-para"},[_vm._v(" "+_vm._s(message.last_message.user.name)+" ")])])]),_c('div',{staticClass:"col-xl-8 col-lg-8 col-md-8 col-sm-12 text-center"},[_c('div',{staticClass:"pers-last-mesg"},[_c('router-link',{staticClass:"btn_viewDetails",attrs:{"to":{
                              path: '/projects/show/'+message.project_id+'/messages/show/'+message.id
                            },"tag":"a","title":message.last_message.message}},[_vm._v(" View Details ")])],1)])])]),_c('div',{staticClass:"col-xl-2 col-lg-2 col-md-4 col-sm-12"},[_c('div',{staticClass:"thr-time text-right"},[_vm._v(" "+_vm._s(message.last_message.created_at)+" ")])])])])}),0):_c('div',[_c('no-record-found')],1)])])],1),_c('div',{key:"3",staticClass:"white-row margin-top-50 invoices-tab"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.invoice_area_loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{key:"2",staticClass:"invoice-wrapper-home"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h3',{staticClass:"global-style-heading"},[_vm._v(" Invoices ")])]),_c('div',{staticClass:"col-md-6 pt-2 d-flex justify-content-end"},[_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterInvoiceType),expression:"filterInvoiceType"}],staticClass:"btn_viewDetails bg-white btnFilter text-dark",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.filterInvoiceType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.getInvoiceData]}},[_c('option',{attrs:{"value":"-1"}},[_vm._v(" Select Invoice ")]),_c('option',{attrs:{"value":"paid"}},[_vm._v(" Paid")]),_c('option',{attrs:{"value":"unpaid"}},[_vm._v(" Unpaid")]),_c('option',{attrs:{"value":"partial"}},[_vm._v(" Partial")])])])])]),_c('div',{},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Invoice Number")]),_c('th',[_vm._v("Project Name")]),_c('th',[_vm._v("Total")]),_c('th',[_vm._v("Remaining")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Action")])])]),(
                    typeof _vm.invoice_data.data !== 'undefined' &&
                      Object.keys(_vm.invoice_data.data).length
                  )?_c('tbody',_vm._l((_vm.invoice_data.data),function(invoice,inv_index){return _c('tr',{key:inv_index},[_c('td',[_c('router-link',{staticClass:"invoice-title global-para-link2",attrs:{"to":{
                          path: '/invoices/show/' + invoice.id
                        },"tag":"p"}},[_vm._v(" "+_vm._s(invoice.invoice_number)+" ")])],1),_c('td',[_c('router-link',{staticClass:"invoice-title global-para-link2",attrs:{"to":{
                          path: '/projects/show/' + invoice.invoice_project_id
                        },"tag":"p"}},[_vm._v(" "+_vm._s(invoice.invoice_project)+" ")])],1),_c('td',[(invoice.status == 'partial')?_c('p',{staticClass:"invoice-total bold-cls"},[_vm._v(" Total Installments : "+_vm._s(_vm._f("numFormat")(invoice.total_installments))+" "),_c('br'),_vm._v(" Installment Amount : "+_vm._s(invoice.currency_symbol)+" "+_vm._s(_vm._f("numFormat")(invoice.partial_total,'0.00'))+" "),_c('br'),_vm._v(" Total : "+_vm._s(invoice.currency_symbol)+" "+_vm._s(_vm._f("numFormat")(invoice.total))+" "),_c('br')]):_c('p',{staticClass:"invoice-total bold-cls"},[_vm._v(" "+_vm._s(invoice.currency_symbol)+" "+_vm._s(_vm._f("numFormat")(invoice.total))+" ")])]),_c('td',[_c('p',{staticClass:"invoice-total bold-cls"},[_vm._v(" "+_vm._s(invoice.currency_symbol)+" "+_vm._s(_vm._f("numFormat")(invoice.total_remaning_amount,'0.00'))+" ")])]),_c('td',[((invoice.status == 'partial') && (invoice.invoice_payments_count == invoice.total_installments))?_c('p',{staticClass:"invoice-status success"},[_vm._v(" "+_vm._s(invoice.status)+" Paid ")]):_c('p',{class:[
                          invoice.status == 'paid'
                            ? 'invoice-status success'
                            : 'invoice-status danger'
                        ]},[_vm._v(" "+_vm._s(invoice.status)+" ")])]),_c('td',[_c('router-link',{staticClass:"blueButton",class:_vm.getPayNowStatus(invoice),attrs:{"to":{
                          path: '/invoices/show/' + invoice.id
                        },"tag":"a"}},[(invoice.status == 'paid')?_c('span',[_vm._v(" Already Paid ")]):_c('span',[_vm._v(" Pay Now")])])],1)])}),0):_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('no-record-found')],1)])])])])])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }