<template>
     <div class="popupArea p-5" v-if="show">
          <div class="white-row text-center p-5 container w-75 position-relative">
            <span class="position-absolute btnClose" @click="closePopup()">✕</span>
            <h2>NEW TO WORKBENCH ?</h2>
            <p class="m-4">Welcome to our project management portal! Access your projects, invoices, and message us for updates. We're here to streamline your project journey. Have questions? Our team is here to assist you. Let's collaborate effectively and achieve success together. Thank you for choosing our portal. Welcome aboard!</p>
            <div class="d-flex justify-content-center pb-3">
              <router-link class="btn_viewDetails mx-1" :to="{path: '/messages'}" tag="a">
                View Messages
              </router-link>

              <router-link class="btn_viewDetails mx-1" :to="{path: '/invoices'}" tag="a">
                View Invoices
              </router-link>
              
              <router-link class="btn_viewDetails mx-1" :to="{path: '/projects'}" tag="a">
                View Projects
              </router-link>

              <router-link class="btn_viewDetails mx-1" :to="{path: '/all-notifications'}" tag="a">
                View Notifications
              </router-link>
              
            </div>
            <div class="d-flex justify-content-center mt-3" v-if="false">
              <input type="checkbox" name="" id="">
              <span class="ml-1">Don't show this again</span>
            </div>
          </div>
        </div>
</template>
<script>
export default {
  name: 'popup',
  data() {
    return {
      show: true,
    }
  },
  methods: {
    closePopup() {
      this.show = false;
    }
  }
}
</script>
