/*global unescape, define */

<style>
      /*sameer working*/
      
      .blueButton,.viewDashBoard .white-row.invoices-tab .invoice-wrapper-home table tbody tr td p.invoice-status.success[data-v-bc517958],section.invoices-area .table-responsive table tbody tr td p.invoice-status.success[data-v-9f88ae48]{white-space:nowrap}.btn_viewDetails{color:#364460;border:1px solid #364460;padding:4px 13px;border-radius:4px;line-height:2;font-size:14px!important}.blueButton,a.refreshData{background:#313f53;color:#fff;text-align:center;animation:none;font-size:14px}.btn_viewDetails:hover{text-decoration:none;background-color:#364460;color:#fff}a.refreshData{padding:6px 30px;float:right;border-radius:4px}a.refreshData:hover{color:#364460;background-color:transparent;text-decoration:none;border:1px solid #364460}a.refreshData::before{content:'Refresh';font-weight:500}a.refreshData svg{display:none}.blueButton{padding:5px 15px;border-radius:4px}.blueButton:hover{color:#364460;background-color:#fff;border:1px solid #364460;text-decoration:none;padding:4px 13px}ul.pagination.pagination.justify-content-center{float:right}.btn_disable{pointer-events:none;background-color:#d3d3d3}._btn-disable{background:#d3d3d3;pointer-events:none}

      .white-row.remove-white-row-home
      {
        padding: 0 !important;
        box-shadow: none !important;
      }
      .popupArea
      {
        position: fixed; width: 100vw; height: 100vh; display: grid; place-items: center; left: 0; top: 0; background: rgb(128 128 128 / 50%); z-index: 99;
      }
      .popupArea .btnClose{right: 0; top: 0; margin: 10px 30px; font-size: 40px; color: red;cursor:pointer}
</style>
<template>
  <div class="viewDashBoard" v-cloak>
    <transition
      name="fade"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <div key="1" v-if="loader" class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else class="dash-board-wrapper>">

        <!-- popup working  -->
        <popup v-if="showPop" />
        
        
        <!-- Notification Area -->
        <div key="4" class="white-row">
          
          <noti isHome='true' />
          
        </div>

        <!-- Messages Area -->
        <div key="2" class="white-row message-wraper-area margin-top-50">
          <div class="row">
            <div class="col-md-6">
              <h3 class="global-style-heading">
                Messages
                <!-- <a @click.prevent="refreshMessages" href="" class="refreshData"
                  ><i class="fas fa-redo-alt"></i
                ></a> -->
              </h3>
            </div>
            <div class="col-md-6 pt-2 d-flex justify-content-end">
              <a @click.prevent="refreshMessages" href="" class="refreshData mr-2"
                  ><i class="fas fa-redo-alt"></i
                ></a>
              <router-link class="btn_viewDetails"
                :to="{
                  path: '/messages'
                }"
                tag="a"
              >
                View All
              </router-link>
            </div>
          </div>
          <br />
          <message-header />
          <transition
            name="fade"
            mode="out-in"
            @beforeLeave="beforeLeave"
            @enter="enter"
            @afterEnter="afterEnter"
          >
            <div key="1" v-if="message_loader" class="white-row loader-route">
              <div class="lds-sub-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
            <div key="2" v-else>
              <div
                class="message-wrapper"
                v-if="
                  typeof project_messages.data !== 'undefined' &&
                    Object.keys(project_messages.data).length
                "
              >
                <div
                  v-for="(message, mes_index) in project_messages.data"
                  :key="mes_index"
                  :class="[
                    mes_index == 0
                      ? 'thr-loop-row margin-top-20'
                      : 'thr-loop-row'
                  ]"
                >
                  <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                      <div class="thrd-name">
                        <router-link
                          class="eclipse-para global-para-link2"
                          :to="{
                            path: '/projects/show/'+message.project_id+'/messages/show/'+message.id
                          }"
                          tag="p"
                        >
                          {{ message.porject_thread_subtitle }}
                        </router-link>
                      </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                      <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                          <div class="per-name">
                            <p class="eclipse-para">
                              {{ message.last_message.user.name }}
                            </p>
                          </div>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 text-center">
                          <div class="pers-last-mesg">
                            <!-- <p class="eclipse-para">
                              {{ message.last_message.message }}
                            </p> -->
                            <router-link
                              class="btn_viewDetails"
                              :to="{
                                path: '/projects/show/'+message.project_id+'/messages/show/'+message.id
                              }"
                              tag="a"
                              :title="message.last_message.message"
                            > View Details </router-link>                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                      <div class="thr-time text-right">
                        {{ message.last_message.created_at }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <no-record-found></no-record-found>
              </div>
            </div>
          </transition>
        </div>

        <!-- Invoice Section Area -->
        <div key="3" class="white-row margin-top-50 invoices-tab">
          <transition
            name="fade"
            mode="out-in"
            @beforeLeave="beforeLeave"
            @enter="enter"
            @afterEnter="afterEnter"
          >
            <div
              key="1"
              v-if="invoice_area_loader"
              class="white-row loader-route"
            >
              <div class="lds-sub-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
            <div v-else key="2" class="invoice-wrapper-home">
              <div class="row">
                <div class="col-md-6">
                  <h3 class="global-style-heading">
                    Invoices
                    <!-- <a @click.prevent="getInvoiceData" href="" class="refreshData"
                      ><i class="fas fa-redo-alt"></i
                    ></a> -->
                  </h3>
                </div>
                <div class="col-md-6 pt-2 d-flex justify-content-end">
                  <!-- <a @click.prevent="getInvoiceData" href="" class="refreshData"
                      ><i class="fas fa-redo-alt"></i
                    ></a>
                  <router-link class="btn_viewDetails"
                    :to="{
                      path: '/invoices'
                    }"
                    tag="a"
                  >
                    View All
                  </router-link> -->
                    <div>
                        <select v-model="filterInvoiceType" class="btn_viewDetails bg-white btnFilter text-dark" @change="getInvoiceData">
                          <option value="-1"> Select Invoice  </option>                        
                          <option value="paid"> Paid</option>
                          <option value="unpaid"> Unpaid</option>
                          <option value="partial"> Partial</option>
                        </select>
                      </div>
                </div>
                
              </div>
              <div class="">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Invoice Number</th>
                      <th>Project Name</th>
                      <th>Total</th>
                      <th>Remaining</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="
                      typeof invoice_data.data !== 'undefined' &&
                        Object.keys(invoice_data.data).length
                    "
                  >
                    <tr
                      v-for="(invoice, inv_index) in invoice_data.data"
                      :key="inv_index"
                    >
                      <td>
                        <router-link
                          class="invoice-title global-para-link2"
                          :to="{
                            path: '/invoices/show/' + invoice.id
                          }"
                          tag="p"
                        >
                          {{ invoice.invoice_number }}
                        </router-link>
                      </td>
                      <td>
                        <router-link
                          class="invoice-title global-para-link2"
                          :to="{
                            path: '/projects/show/' + invoice.invoice_project_id
                          }"
                          tag="p"
                        >
                          {{ invoice.invoice_project }}
                        </router-link>
                      </td>
                      <td>
                        <p v-if="invoice.status == 'partial'" class="invoice-total bold-cls">
                          Total Installments : {{ invoice.total_installments | numFormat }} <br>
                          Installment Amount : {{ invoice.currency_symbol }} {{ invoice.partial_total | numFormat('0.00') }} <br>
                          Total : {{ invoice.currency_symbol }} {{ invoice.total | numFormat }} <br>
                        </p>
                        <p v-else class="invoice-total bold-cls">
                          {{ invoice.currency_symbol }} {{ invoice.total | numFormat }}
                        </p>

                      </td>
                      <td>
                        <p class="invoice-total bold-cls">
                          {{ invoice.currency_symbol }} {{ invoice.total_remaning_amount | numFormat('0.00') }}
                        </p>
                      </td>
                      <td>
                        <p
                          v-if="(invoice.status == 'partial') && (invoice.invoice_payments_count == invoice.total_installments)"
                          class="invoice-status success"
                        >
                          {{ invoice.status }} Paid
                        </p>
                        <p
                          v-else
                          :class="[
                            invoice.status == 'paid'
                              ? 'invoice-status success'
                              : 'invoice-status danger'
                          ]"
                        >
                          {{ invoice.status }}
                        </p>
                      </td>
                      <td>
                        <router-link :class="getPayNowStatus(invoice)" class="blueButton" :to="{
                            path: '/invoices/show/' + invoice.id
                          }" tag="a">
                          <span v-if="invoice.status == 'paid'"> Already Paid </span>
                          <span v-else> Pay Now</span>
                        </router-link>
                        
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4">
                        <no-record-found></no-record-found>
                      </td>
                    </tr>
                  </tbody>
                </table>
                
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import norecordfound from "../../../components/no-record-found";
import messageHeader from "../../../components/message-header";
import noti from "../../../views/all-notifications/index-notification";
import popup from "../popup.vue";

export default {
  components: {
    "no-record-found": norecordfound,
    messageHeader,
    noti,
    popup,
  },
  data() {
    return {
      filter_area_loader: true,
      opens: "right",
      single: true,
      filtersData: {},
      disableSubmitBtn: false,
      project_messages: {},
      message_loader: true,
      invoice_area_loader: true,
      invoice_data: {},
      loader: true,
      filterInvoiceType: '-1',
      showPop: false,
    };
  },

  mounted() {
    document.title =
      "PNC Work Bench - " + this.$router.app._route.meta.pageTitle;
    // Set The Date of Date Range picker on First Day of Month
    this.getMessageData();
    this.getInvoiceData();
    this.loader = false;


    this.showPop = this.$route.query.msg ? true : false;

  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }    
  },
  methods: {
    refreshInvoices() {
      this.getInvoiceData();
    },
    refreshMessages() {
      this.getMessageData();
    },
    async getMessageData() {
      const $this = this;
      $this.message_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "dashboard/get-dashboad-thread-messages?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.project_messages = response.data;
        }
        $this.message_loader = false;
      });
    },
    async getInvoiceData() {
      const $this = this;
      $this.invoice_area_loader = true;
      let query_params = '';

      if(this.filterInvoiceType && this.filterInvoiceType != '-1') {
        
        query_params = '?type=' + this.filterInvoiceType;
      }

      const data = $this.$store.dispatch("getHttp", {
        url: "dashboard/get-dashboad-invoices" + query_params
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.invoice_data = response.data;
        }
        $this.invoice_area_loader = false;
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    getPayNowStatus(invoice) {
      return invoice.status == 'paid' ? '_btn-disable' : '';
    }
  }
};

</script>

<style lang="scss" scoped>
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.viewDashBoard {
  .white-row {
    min-height: 220px;
    background: #fff;
    padding: 40px 33px 40px;
    box-shadow: 0 0 10px #00000029;
    border-radius: 10px;
    // Top Filter Css
    .filter-Calculation-Area {
      padding: 40px 15px 0;
      position: relative;
      .white-row.loader-route {
        z-index: 1;
        width: 100%;
        background: #fff;
        .lds-sub-ripple {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .box-dash-wraper {
        width: 100%;
        padding: 0 9px 0 13px;
        margin-bottom: 15px;
        .box-dash {
          background: #faf9f7;
          padding: 20px 15px;
          p {
            margin: 0;
            &.txt {
              margin: 0;
              letter-spacing: 0.28px;
              color: #323f54;
              font-size: 14px;
              font-weight: bold;
              padding: 12px 0 15px;
            }
            &.numb {
              margin: 0;
              letter-spacing: 0.28px;
              color: #323f54;
              font-size: 28px;
              font-weight: bold;
              padding: 0;
            }
          }
          i,
          span,
          svg {
            color: #2d9cea;
            font-size: 23px;
            &:before {
              color: #2d9cea;
            }
          }
        }
      }
      .horizontal-row {
        padding: 30px 0 23px;
        hr {
          margin: 0;
        }
        &:after {
          content: "";
          display: block;
          clear: both;
        }
      }
      .currency-box-dash-wrapper {
        width: 100%;
        margin-bottom: 20px;
        .currency-box-dash {
          position: relative;
          background: #faf9f7;
          padding: 15px 20px 15px 45px;

          .wrap-icon {
            position: absolute;
            top: 23px;
            left: 15px;
            width: 26px;
            height: 26px;
            background: #3fae2a;
            color: #fff;
            border-radius: 50%;
            padding: 0 8px;
            font-size: 17px;
          }
          p {
            margin: 0;
            letter-spacing: 0.28px;
            color: #2a1c0b;
            &.txt {
              padding: 1px 0 1px 7px;
            }
            &.numb {
              width: 100%;
              letter-spacing: 0px;
              color: #364460;
              font-size: 15px;
              font-weight: bold;
              padding-top: 9px;
              padding-right: 5px;
            }
          }
        }
      }
    }
    // Message Section Css
    .thr-loop-row {
      border-bottom: 1px solid #ebebeb;
      padding: 20px 0;
      p {
        margin: 0;
        letter-spacing: 0.28px;
        color: #2a1c0b;
      }
      .thrd-name {
        p {
          letter-spacing: 0.28px;
          color: #323f54;
          font-weight: bold;
        }
      }
      .per-name {
        p {
          font-weight: 400;
        }
      }
      .pers-last-mesg {
        p {
          font-weight: 100;
        }
      }
      .thr-time {
        font-weight: 100;
      }
    }

    // invoicrd tab
    &.invoices-tab {
      h3 {
        margin-bottom: 30px;
      }
      .white-row.loader-route {
        .lds-sub-ripple {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .invoice-wrapper-home {
        
          table {
            margin-bottom: 0;
            thead {
              th {
                letter-spacing: 0.28px;
                color: #2a1c0b;
                font-weight: bold;
                white-space: nowrap;
              }
            }
            tbody {
              tr {
                td {
                  vertical-align: middle;
                  // white-space: nowrap;
                  p {
                    margin-bottom: 0px;
                    color: #323f54;
                    font-weight: 300;
                    letter-spacing: 0.28px;
                    &.invoice-title {
                      letter-spacing: 0.28px;
                      // font-weight: bold;
                    }
                    &.invoice-total {
                      // font-weight: bold;
                    }
                    &.invoice-status {
                      display: inline-block;
                      background: #ece9df 0% 0% no-repeat padding-box;
                      border: 1px solid #00000000;
                      border-radius: 15px;
                      padding: 3px 17px;
                    }
                    &.invoice-icon {
                      display: inline-block;
                      background: #1190ee;
                      color: #fff !important;
                      padding: 6px 10px;
                      border-radius: 5px;
                    }
                    &.invoice-status {
                      font-size: 10px;
                      border-radius: 10px;
                      text-transform: capitalize;
                      font-weight: bold !important;
                      line-height: 20px !important;
                      padding: 0 10px;
                      text-align: center;
                      display: inline-block;
                      border: 1px solid #00000000;
                      // width: 100%;
                      &.danger {
                        background: red;
                        color: #fff !important;
                      }
                      &.success {
                        background: rgba(0, 128, 0, 0.72);
                        color: #fff !important;
                      }
                    }
                  }
                }
              }
            }
          }
        
      }
    }
    &.message-wraper-area {
      position: relative;
      .white-row.loader-route {
        margin-top: 20px;
        .lds-sub-ripple {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

// custom Styling
.viewDashBoard .white-row .filter-Calculation-Area .horizontal-row {
  padding-top: 10px;
}

// Extra large devices (lg and less)
@media screen and (max-width: 1199px) {
  .text-right-custom {
    text-align: right;
  }
}
// Extra large devices (md and less)
@media screen and (max-width: 991px) {
  .text-right-custom {
    text-align: right;
  }
  .viewDashBoard
    .white-row
    .filter-Calculation-Area
    .currency-box-dash-wrapper
    .currency-box-dash
    p.numb {
    font-size: 17px;
    padding-top: 8px;
  }
}

// Extra large devices (sm and up)
@media screen and (max-width: 767px) {
  .text-right-custom {
    text-align: left;
  }
  p {
    &.payment-custom-padding {
      padding-top: 0 !important;
      padding-left: 8px;
    }
  }
  .viewDashBoard
    .white-row
    .filter-Calculation-Area
    .currency-box-dash-wrapper
    .currency-box-dash
    p.numb {
    font-size: 15px;
    padding-top: 0;
    text-align: left;
    padding-left: 23px;
  }
}

// Extra large devices (xs and up)
@media screen and (max-width: 575px) {
  .text-right-custom {
    text-align: left;
  }
  .text-right-xs {
    text-align: left;
    padding-top: 0 !important;
    padding-left: 23px;
  }
  .viewDashBoard
    .white-row
    .filter-Calculation-Area
    .currency-box-dash-wrapper
    .currency-box-dash
    p.numb {
    font-size: 15px;
    padding-top: 0;
  }
}

// Extra large devices (xl, 1200px and up)
@media (min-width: 1200) {
  .text-right-custom {
    text-align: right;
  }
}



</style>
